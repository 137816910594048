<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单编号：" prop="id">
              <el-input
                v-model="queryInfo.condition.id"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单状态：" prop="businessStatus">
              <el-input
                v-model="queryInfo.condition.businessStatus"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配送完成日期：" prop="industryType">
              <el-date-picker
                @change="change"
                v-model="valuechange"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="订类型：" prop="businessStatus">
              <el-input
                v-model="queryInfo.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left"
              >重置</el-button
            >
            <el-button type="primary" @click="search" icon="el-icon-search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="finance">
      <div class="fin-box" style="padding-left: 40px">
        <div class="fin-li">
          <div>总配送费</div>
          <div class="fin-li-butto">￥255</div>
        </div>
        <div class="fin-li">
          <div>基础配送费</div>
          <div class="fin-li-butto">￥255</div>
        </div>
        <div class="fin-li">
          <div>总小费</div>
          <div class="fin-li-butto">￥255</div>
        </div>
        <div class="fin-li">
          <div>平台配送费总抽佣</div>
          <div class="fin-li-butto">￥255</div>
        </div>
      </div>
    </div>
    <div class="tab">
      <div style="display: flex; justify-content: space-between">
        <div>
          <el-button @click="backList" v-show="detailShow">返回</el-button>
        </div>
        <div class="add" style="text-align: right">
          <el-button type="primary" @click="added">导出</el-button>
        </div>
      </div>

      <div v-show="!detailShow">
        <el-table
          :data="tableData"
          style="width: 100%"
          show-summary
          height="calc(100vh - 380px)"
          border
          :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }"
        >
          <el-table-column
            prop="businessName"
            label="配送员信息"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="allowPayoutsNum"
            label="骑手账号"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="骑手归属" align="center">
          </el-table-column>
          <el-table-column prop="name" label="配送区域" align="center">
          </el-table-column>
          <el-table-column
            prop="scope"
            label="配送费总金额"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="industryType"
            label="基础配送费"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="industryType" label="小费" align="center">
          </el-table-column>

          <el-table-column prop="industryType" label="平台抽成" align="center">
          </el-table-column>
          <el-table-column
            prop="industryType"
            label="骑手结算金额"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop=" "
            label="操作"
            width="200"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row.id)"
                >查看明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.currPage"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-show="detailShow">
        <el-table
          :data="tableData"
          style="width: 100%"
          show-summary
          height="calc(100vh - 380px)"
          border
          :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }"
        >
          <el-table-column
            prop="businessName"
            label="配送员信息"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="allowPayoutsNum"
            label="骑手账号"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="骑手归属" align="center">
          </el-table-column>
          <el-table-column prop="name" label="配送区域" align="center">
          </el-table-column>
          <el-table-column
            prop="scope"
            label="配送费总金额"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="industryType"
            label="基础配送费"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="industryType" label="小费" align="center">
          </el-table-column>

          <el-table-column prop="industryType" label="平台抽成" align="center">
          </el-table-column>
          <el-table-column
            prop="industryType"
            label="骑手结算金额"
            width="150"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo1.currPage"
            :page-size="queryInfo1.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDialogtwo: false,
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          id: "",
          businessStatus: "",
          startDate: "",
          endDate: "",
        },
      },
      total1: 0,
      queryInfo1: {
        pageSize: 10,
        currPage: 1,
        condition: {},
      },
      valuechange: "",
      form: {},
      detailShow: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    change() {
      console.log(this.valuechange);
      this.queryInfo.condition.startDate = this.newDate(this.valuechange[0]);
      this.queryInfo.condition.endDate = this.newDate(this.valuechange[1]);
    },
    newDate(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
    },
    // 查看明细
    handleDetail(row) {
      this.detailShow = true;
      this.businessName = row.businessName;
      this.businessId = row.id;
      // this.getList1()
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/transaction/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data, "response.data.data");
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    search() {
      this.queryInfo.condition = this.form;
      this.getList();
    },
    reset() {
      this.form = {};
      this.queryInfo.condition = {};
      this.getList();
    },

    // 返回-列表
    backList() {
      this.detailShow = false;
      this.businessName = null;
      this.businessId = null;
      // this.getList()
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    padding: 20px 20px 20px 0;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 15px;

    // justify-content: space-around;
    .fin-box {
      display: flex;

      .fin-li {
        margin-left: 15px;
        text-align: center;

        .fin-li-butto {
          margin-top: 10px;
        }
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }
      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center;
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .order1 {
      display: flex;
      margin-bottom: 20px;

      div {
        margin-right: 20px;
      }
    }

    .order2 {
      display: flex;

      .li {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        text-align: center;
        padding-top: 30px;

        margin-right: 20px;
      }

      .li2 {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        align-items: center;

        margin-right: 20px;
        display: flex;

        .li-left {
          color: rgba(251, 142, 0, 0.99);
          margin-left: 10px;
          margin-right: 10px;
        }

        .li-right {
          padding-top: 15px;
        }
      }
    }

    .order3 {
      font-weight: 700;
      color: #333333;
      margin-top: 30px;
    }

    .order4 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      div {
        width: 50%;
        margin-top: 10px;
      }
    }
  }

  .diasale {
    color: #333333;
    font-size: 16px;

    .sale1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .sale2 {
      margin-top: 5px;

      .de2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .de-left {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
          }

          .de-jj {
            margin-left: 5px;

            div {
              height: 33px;
              line-height: 33px;
            }
          }
        }

        .de-right {
          color: #e30606;
        }
      }

      .de3 {
        margin-top: 10px;

        .de-img {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }

        border-bottom: 1px solid #e30606;
        padding-bottom: 40px;
      }
    }

    .sale3 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    .sale4 {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 680px;
        height: 60px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(121, 121, 121, 0.26);
      }
    }
  }
}
</style>
